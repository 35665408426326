'use client';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import AlertsSubscriptionPopover from '@haaretz/s-alerts-subscription-popover';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import isInBpRange from '@haaretz/s-breakpoint-utils/isInBpRange';
import Button from '@haaretz/s-button';
import useClientQuery from '@haaretz/s-client-data-hooks/useClientQuery';
import { site } from '@haaretz/s-consts';
import ErrorBoundary from '@haaretz/s-error-boundary';
import { useUserAlertsRegistrationPersonalMutation } from '@haaretz/s-mutations/UserAlertsRegistration';
import useBi from '@haaretz/s-use-bi';
import useBreakpoint from '@haaretz/s-use-breakpoint';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import * as React from 'react';
import s9 from 'style9';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    justifySelf: 'end',
    alignSelf: 'center',
    gridColumnEnd: -1,
    gridRowStart: 1,
    gridRowEnd: 3,
    ...merge(
      mq({
        until: 's',
        value: {
          alignSelf: 'start',
        },
      }),
      mq({
        from: 'l',
        value: {
          justifySelf: 'start',
          gridColumnStart: 2,
          gridColumnEnd: 3,
          gridRowStart: 2,
          gridRowEnd: 3,
          marginTop: 0,
          // margin Inline start is negative so bell Icon will be cut exactly
          marginInlineStart: space(-2),
        },
      })
    ),
  },
  startIcon: {
    ...merge(
      mq({
        from: 'l',
        value: {
          fontSize: space(5),
        },
      })
    ),
  },
  baseWithDateModified: {
    ...merge(
      mq({
        until: 'l',
        value: {
          gridRowStart: 1,
          gridRowEnd: 3,
          justifySelf: 'end',
          alignSelf: 'start',
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          marginBottom: space(1),
        },
      })
    ),
  },

  fadeOutButton: {
    opacity: 0,
    transitionDuration: '0.7s',
    transitionProperty: 'opacity',
  },

  btnContent: {
    ...typesetter(-2),
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
});

export interface AlertsRegistrationButtonProps {
  /** contentId for the author */
  contentId: string;
  /** author name  for the alerts registration*/
  authorName: string;
  /** for UI management */
  withDateModified?: boolean;
}

const btnTexts = {
  mobile: {
    signup: fork({ default: 'עקוב', hdc: 'Sign up' }),
    unsubscribe: fork({ default: 'להסרה', hdc: 'Unsubscribe' }),
  },
  desktop: {
    signup: fork({ default: 'התראות במייל', hdc: 'Sign up' }),
    unsubscribe: fork({ default: 'להסרה', hdc: 'Unsubscribe' }),
  },
} as const;

interface IsSubscribed {
  subscribed?: boolean;
}

type BtnKind = keyof (typeof btnTexts)[keyof typeof btnTexts];

function AlertsRegistrationButton({
  contentId,
  authorName,
  withDateModified,
}: AlertsRegistrationButtonProps) {
  const platform = usePlatform();
  const user = useUser('serverValue');
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);
  const [fadeOutBtn, setFadeOutBtn] = React.useState<BtnKind | false>(false);
  const biAction = useBi();
  const breakpoint = useBreakpoint();
  const followBtnRef = React.useRef(null);

  const isApp = platform === 'app';

  const { data: userSubscriptionData } = useClientQuery<IsSubscribed>({
    url: 'https://services.haaretz.co.il/user-alerts/is-subscribed',
    fetchOptions: { method: 'GET' },
    variables: { resourceId: contentId, resourceType: 'writer', site, email: user?.userMail },
    cacheKey: 'writerAlerts',
    clientOptions: { enabled: !!user.userMail },
  });

  const isSubscribed = userSubscriptionData?.subscribed;

  const [btnKind, setBtnKind] = React.useState<BtnKind>(isSubscribed ? 'unsubscribe' : 'signup');

  const [status, setStatus] = React.useState(isApp ? 'Unfollow' : '');

  const setSubscribeBtn = () => {
    setBtnKind('signup');
  };

  const setUnsubscribeBtn = () => {
    setBtnKind('unsubscribe');
  };

  const onUnfollow = React.useCallback(() => {
    if (status !== 'Unfollow') {
      setStatus('Unfollow');
      setFadeOutBtn('unsubscribe');
      setUnsubscribeBtn();
    }
  }, [status]);

  const onFollow = React.useCallback(() => {
    if (status !== 'Follow') {
      setStatus('Follow');
      setFadeOutBtn('signup');
      setSubscribeBtn();
    }
  }, [status]);

  // NOTE: In application we use `setFollow` and `setUnfollow` events to follow up the status of the author,
  // but historically the sence of the events is opposite to the name of the event.
  useDocumentEventListener('setFollow', onUnfollow, false);
  useDocumentEventListener('setUnfollow', onFollow, false);

  const popoverPlacement = isInBpRange({ from: 'l', currentBp: breakpoint })
    ? 'bottom-start'
    : 'bottom-end';

  const handleOpenPopoverStateChangeHandler = (boolean: boolean) => {
    setOpenPopover(boolean);
  };

  const action = btnKind === 'signup' ? 'subscribe' : 'unsubscribe';
  const userAlertsMutation = useUserAlertsRegistrationPersonalMutation();

  const biDataForRegisteredUsers = {
    campaign_name: 'follow writer',
    feature: 'Writer Alert - article page',
    feature_type: 'Content',
    newsletter_segment_id: contentId,
    newsletter_segment_name: authorName,
    newsletter_email: user.userMail,
  };

  function anonymousFollowBtnClick() {
    biAction({
      action_id: 91,
      campaign_details: 'open UI',
      campaign_name: 'follow writer',
      feature: 'Writer Alert - article page',
      feature_type: 'Content',
      newsletter_segment_id: contentId,
      newsletter_segment_name: authorName,
    });
  }

  async function onFollowBtnClick() {
    if (action === 'subscribe') {
      if (!isApp) {
        userAlertsMutation.mutate({
          input: {
            isUnsubscribe: false,
            site,
            resourceType: 'writer',
            resourceId: contentId,
          },
        });
      }
      setFadeOutBtn('signup');
      biAction({
        action_id: 9,
        campaign_details: 'follow',
        ...biDataForRegisteredUsers,
      });
    }
    if (action === 'unsubscribe') {
      if (!isApp) {
        userAlertsMutation.mutate({
          input: {
            isUnsubscribe: true,
            site,
            resourceType: 'writer',
            resourceId: contentId,
          },
        });
      }
      setFadeOutBtn('unsubscribe');
      biAction({
        action_id: 8,
        campaign_details: 'unfollow',
        ...biDataForRegisteredUsers,
      });
    }
  }

  const onTransitionEnd = () => {
    if (fadeOutBtn === 'signup') {
      setUnsubscribeBtn();
      setFadeOutBtn(false);
    }
    if (fadeOutBtn === 'unsubscribe') {
      setSubscribeBtn();
      setFadeOutBtn(false);
    }
  };

  const isFollow = status === 'Follow';

  return (
    <>
      <Button
        size="small"
        priority="tertiary"
        variant={btnKind === 'signup' ? 'brand' : 'danger'}
        ref={followBtnRef}
        startIcon={'bell'}
        as={isApp ? 'a' : 'button'}
        id="btn-author-alerts"
        styleExtend={[
          c.base,
          withDateModified && c.baseWithDateModified,
          fadeOutBtn && c.fadeOutButton,
        ]}
        startIconStyleExtend={[c.startIcon]}
        {...(user.userType !== 'anonymous'
          ? { onClick: onFollowBtnClick }
          : { onClick: anonymousFollowBtnClick })}
        onTransitionEnd={onTransitionEnd}
        {...(isApp
          ? {
              href: `https://author.alerts.${isFollow ? 'off' : 'on'}/`,
              target: '_blank',
              rel: 'noreferrer',
            }
          : {})}
      >
        <span className={s9(c.btnContent)}>{btnTexts[isApp ? 'mobile' : platform][btnKind]}</span>
      </Button>

      {!isApp && user.userType === 'anonymous' ? (
        <AlertsSubscriptionPopover
          setUnsubscribeButton={setUnsubscribeBtn}
          isOpenFromParent={openPopover}
          contentId={contentId}
          alertType="writer"
          onToggle={handleOpenPopoverStateChangeHandler}
          refersToRef={followBtnRef}
          popoverPlacement={popoverPlacement}
          authorName={authorName}
        />
      ) : null}
    </>
  );
}

export default function AlertsRegistrationButtonWrapper(props: AlertsRegistrationButtonProps) {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={null}>
        <AlertsRegistrationButton {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
}
